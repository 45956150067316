<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <!--单页数据-->
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="卡分类" name="first">
        <el-button
          type="primary"
          style="float: right; margin-bottom: 1rem"
          @click="addTypeDialog = true"
          >新增</el-button
        >
        <el-table
          :data="cardList"
          row-key="id"
          :tree-props="{ children: 'childrens' }"
        >
          <el-table-column label="ID" prop="id"></el-table-column>
          <el-table-column
            label="会员等级名称"
            prop="member_name"
          ></el-table-column>
          <el-table-column
            label="会员等级"
            prop="member_level"
          ></el-table-column>
          <el-table-column label="是否打折" prop="is_discount">
            <template slot-scope="scope">
              {{ scope.row.is_discount ? "是" : "否" }}
            </template>
          </el-table-column>
          <el-table-column label="折扣" prop="discount"></el-table-column>
          <el-table-column
            label="会员权益"
            width="200"
            prop="member_equity"
          ></el-table-column>
          <el-table-column
            label="畅聊次数"
            prop="chatting_count"
          ></el-table-column>
          <el-table-column label="平台" prop="platform"></el-table-column>

          <el-table-column width="200" label="创建时间" prop="create_time">
          </el-table-column>
          <el-table-column width="200" label="更新日期" prop="update_time">
          </el-table-column>
          <el-table-column label="状态" prop="status">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1" style="color: green"
                >已生效</span
              >
              <span v-else-if="scope.row.status == 0" style="color: red"
                >未生效</span
              >
            </template>
          </el-table-column>
          <el-table-column width="280" label="操作">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="small"
                @click="toCardDetails(scope.row)"
                >查看详情</el-button
              >
              <el-button
                type="warning"
                size="small"
                @click="openTypeUpdate(scope.row)"
                >编辑</el-button
              >
              <el-button type="danger" size="small" @click="frozen(scope.row)"
                >冻结</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <paging-fy
          @currentPageChange="handleCurrentChange"
          :currentPage="currentPage"
          :total="cardTotal"
        ></paging-fy>
      </el-tab-pane>
      <el-tab-pane label="卡类型" name="second">
        <el-button @click="addDialog = true">新增</el-button>
        <el-table :data="cardList">
          <el-table-column label="ID" prop="id"></el-table-column>

          <el-table-column
            label="会员卡名称"
            prop="member_name"
          ></el-table-column>
          <el-table-column label="会员卡类型" prop="level_id">
            <template slot-scope="scope">
              {{ levelName(scope.row.level_id).member_name }}
            </template>
          </el-table-column>
          <el-table-column label="点数有效期" prop="duration">
            <template slot-scope="scope">
              {{ scope.row.duration + "月" }}
            </template>
          </el-table-column>
          <el-table-column label="价格" prop="amount"></el-table-column>
          <el-table-column
            label="折扣价"
            prop="discount_amout"
          ></el-table-column>
          <el-table-column label="赠送积分" prop="give_coin"></el-table-column>
          <el-table-column label="创建时间" prop="create_time">
            <template slot-scope="scope">
              {{ new Date(scope.row.create_time).Format("yyyy-MM-dd") }}
            </template>
          </el-table-column>
          <el-table-column width="200" label="更新日期" prop="update_time">
            <!-- <template slot-scope="scope">
          {{end_time(scope.row.create_time)}}
        </template> -->
          </el-table-column>
          <el-table-column label="状态" prop="status">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1" style="color: green"
                >已生效</span
              >
              <span v-else-if="scope.row.status == 0" style="color: red"
                >未生效</span
              >
            </template>
          </el-table-column>

          <el-table-column width="280" label="操作">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="small"
                @click="toCardDetails(scope.row)"
                >查看详情</el-button
              >
              <el-button
                type="warning"
                size="small"
                @click="openUpdate(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                size="small"
                @click="frozen(scope.row, 0)"
                v-if="scope.row.status == 1"
                >下架
              </el-button>
              <el-button
                type="success"
                size="small"
                @click="frozen(scope.row, 1)"
                v-if="scope.row.status == 0"
                >启用
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <paging-fy
          @currentPageChange="handleCurrentChange"
          :currentPage="currentPage"
          :total="cardTotal"
        ></paging-fy>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      :width="ks ? '95%' : ''"
      :visible="addTypeDialog || updateTypeDialog"
      :title="
        addTypeDialog ? '新增会员等级' : updateTypeDialog ? '修改会员等级' : ''
      "
      :before-close="handleClose"
    >
      <el-form
        v-model="form"
        label-width="auto"
        style="display: flex; justify-content: center; flex-wrap: wrap"
      >
        <div :style="ks ? 'width: 100%' : ''">
          <el-form-item label="会员等级">
            <el-input
              type="number"
              v-model="form.member_level"
              :placeholder="temp.member_level"
            ></el-input>
          </el-form-item>
          <el-form-item label="会员等级名称">
            <el-input
              v-model="form.member_name"
              :placeholder="temp.member_name"
            ></el-input>
          </el-form-item>

          <el-form-item label="是否打折">
            <el-checkbox v-model="form.is_discount"></el-checkbox>
          </el-form-item>
          <el-form-item label="享受折扣" v-if="form.is_discount">
            <el-input
              v-model="form.discount"
              :placeholder="temp.discount"
            ></el-input
            ><span class="form_text">次</span>
          </el-form-item>
          <el-form-item label="卡类型">
            <el-radio v-model="cardType" :label="1">一级卡</el-radio>
            <el-radio v-model="cardType" :label="2">二级卡</el-radio>
          </el-form-item>
          <el-form-item label="绑定一级卡" v-if="cardType == 2">
            <el-select v-model="form.parent_id">
              <el-option
                v-for="(item, index) in cardList"
                :key="index"
                :label="item.member_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="畅聊次数">
            <el-input
              v-model="form.chatting_count"
              :placeholder="temp.chatting_count"
            ></el-input
            ><span class="form_text">折</span>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="会员权益">
            <el-input
              type="textarea"
              v-model="form.member_equity"
              :rows="5"
              :placeholder="temp.member_equity"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="说明">
            <el-input
              type="textarea"
              v-model="form.introduction"
              :placeholder="temp.introduction"
              :rows="5"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-if="addTypeDialog" @click="addCardLevel()"
          >确 定</el-button
        >
        <el-button type="primary" v-if="updateTypeDialog" @click="updateType()"
          >确 定</el-button
        >
        <el-button @click="handleClose()">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible="addCardDialog || updateCardDialog"
      :title="
        addCardDialog ? '新增会员卡' : updateCardDialog ? '修改会员卡' : ''
      "
      :before-close="handleClose"
    >
      <el-form
        v-model="form"
        label-width="auto"
        style="display: flex; justify-content: space-around"
      >
        <div>
          <el-form-item label="会员卡种类">
            <el-cascader
              v-model="level_id"
              :options="cardLevel"
              remote
              @focus="getCardLevel"
              :props="{
                children: 'childrens',
                label: 'member_name',
                value: 'id',
              }"
              :show-all-levels="false"
              @change="getCardTypeID"
              :placeholder="levelName(temp.level_id).member_name"
            >
            </el-cascader>
          </el-form-item>

          <el-form-item label="会员卡价格">
            <el-input
              v-model="form.amount"
              :placeholder="temp.amount"
            ></el-input
            ><span class="form_text">元</span>
          </el-form-item>
          <el-form-item label="会员卡名称">
            <el-input
              v-model="form.member_name"
              :placeholder="temp.member_name"
            ></el-input>
          </el-form-item>
          <el-form-item label="折扣价">
            <el-input
              v-model="form.discount_amout"
              :placeholder="temp.discount_amout"
            ></el-input
            ><span class="form_text">元</span>
          </el-form-item>
          <el-form-item label="赠送积分">
            <el-input
              v-model="form.give_coin"
              :placeholder="temp.give_coin"
            ></el-input>
          </el-form-item>
          <el-form-item label="有效期">
            <el-input
              v-model="form.duration"
              :placeholder="temp.duration"
            ></el-input
            ><span class="form_text">月</span>
          </el-form-item>
          <el-form-item label="会员卡说明">
            <el-input
              type="textarea"
              v-model="form.introduction"
              :placeholder="temp.introduction"
              style="width: inherit"
            ></el-input>
          </el-form-item>
        </div>
        <div v-if="form.level_id" style="width: 50%">
          <el-form-item label="会员等级权益">
            <div>
              {{ levelName(form.level_id).member_equity }}
            </div>
          </el-form-item>
          <el-form-item label="会员等级说明">
            <div>
              {{ levelName(form.level_id).introduction }}
            </div>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-if="addCardDialog" @click="addCard()"
          >确 定</el-button
        >
        <el-button type="primary" v-if="updateCardDialog" @click="updateType()"
          >确 定</el-button
        >
        <el-button @click="handleClose()">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :width="ks ? '95%' : ''"
      :visible="addDialog || updateDialog"
      :title="addDialog ? '新增会员卡' : updateDialog ? '修改会员卡' : ''"
      :before-close="handleClose"
    >
      <el-form
        v-model="form"
        label-width="auto"
        style="display: flex; justify-content: space-around"
      >
        <div>
          <el-form-item label="会员卡种类">
            <el-cascader
              v-model="level_id"
              :options="cardLevel"
              remote
              @focus="getCardLevel"
              :props="{
                children: 'childrens',
                label: 'member_name',
                value: 'id',
              }"
              :show-all-levels="false"
              @change="getCardTypeID"
              :placeholder="levelName(temp.level_id).member_name"
            >
            </el-cascader>
          </el-form-item>
          <!-- <el-form-item
            label="尊享卡种类"
            v-if="levelName(level_id).member_name == '尊享卡' || temp.level_id"
          >
            <el-select v-model="form.level_id">
              <el-option
                v-for="(item, index) in levelName(level_id).childrens"
                :key="index"
                :label="item.member_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="会员卡价格">
            <el-input
              v-model="form.amount"
              :placeholder="temp.amount"
            ></el-input
            ><span class="form_text">元</span>
          </el-form-item>
          <el-form-item label="会员卡名称">
            <el-input
              v-model="form.member_name"
              :placeholder="temp.member_name"
            ></el-input>
          </el-form-item>
          <el-form-item label="折扣价">
            <el-input
              v-model="form.discount_amout"
              :placeholder="temp.discount_amout"
            ></el-input
            ><span class="form_text">元</span>
          </el-form-item>
          <el-form-item label="赠送积分">
            <el-input
              v-model="form.give_coin"
              :placeholder="temp.give_coin"
            ></el-input>
          </el-form-item>
          <el-form-item label="有效期">
            <el-input
              v-model="form.duration"
              :placeholder="temp.duration"
            ></el-input
            ><span class="form_text">月</span>
          </el-form-item>
          <el-form-item label="会员卡说明">
            <el-input
              type="textarea"
              v-model="form.introduction"
              :placeholder="temp.introduction"
              style="width: inherit"
            ></el-input>
          </el-form-item>
        </div>
        <div v-if="form.level_id" style="width: 50%">
          <el-form-item label="会员等级权益">
            <div>
              {{ levelName(form.level_id).member_equity }}
            </div>
          </el-form-item>
          <el-form-item label="会员等级说明">
            <div>
              {{ levelName(form.level_id).introduction }}
            </div>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-if="addDialog" @click="addCard()"
          >确 定</el-button
        >
        <el-button type="primary" v-if="updateDialog" @click="update()"
          >确 定</el-button
        >
        <el-button @click="handleClose()">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "RegisterMsg",
  data() {
    return {
      ks: "",
      nav: {
        firstNav: "会员管理中心",
        secondNav: "会员卡管理",
      },
      activeName: "first",
      currentPage: 1,
      cardList: [],
      cardTotal: 0,
      allCardLevel: [],
      cardLevel: [],
      level_id: null,
      firstLevelCard: [],
      temp: {},
      form: {
        platform: "体育宝",
        is_teach_paypal: true,
        status: 1,
      },
      addTypeDialog: false,
      addDialog: false,
      addCardDialog: false,
      updateTypeDialog: false,
      updateCardDialog: false,
      updateDialog: false,
      cardType: 1,
    };
  },
  updated() {
    this.ks = window.screen.width < 768;
  },
  mounted() {
    this.getCardLevelList();
  },
  computed: {
    discount_amout() {
      let discount = this.form.discount
        ? this.form.discount
        : this.temp.discount;
      let amout = this.form.amout ? this.form.amout : this.temp.amout;
      return this.form.is_discount ? (discount * amout).toFixed(2) : amout;
    },
  },
  methods: {
    getCardTypeID(v) {
      this.form.level_id = v[v.length - 1];
    },
    end_time(start_time) {
      let a = new Date(start_time);
      a.setFullYear(a.getFullYear() + 1);
      a.setDate(a.getDate() - 1);
      return a.Format("yyyy-MM-dd");
    },
    levelName(id) {
      let a = this.allCardLevel.find((item, index, arr) => {
        return item.id == id;
      });
      if (a) {
        return a;
      } else return {};
    },
    // addLevelName(id_list) {
    //   let a = this.allCardLevel.find((item, index, arr) => {
    //     return item.id == id_list[id_list.length - 1];
    //   });
    //   console.log(a,id_list);
    //   if (a) {
    //     return a;
    //   } else return {};
    // },

    getCardList() {
      let url = "/user/userMemberCard/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            currentPage: this.currentPage,
            platform: "体育宝",
          },
        })
        .then((res) => {
          this.cardList = res.data.data.rows;
          this.cardTotal = res.data.data.total;
        });
    },
    handleCurrentChange(v) {
      this.currentPage = v;
      this.getCardList();
    },
    getAllCardLevel() {
      let url = "/user/userMemberLevel/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            pageSize: 99,
            platform: "体育宝",
            status: 1,
          },
        })
        .then((res) => {
          this.allCardLevel = res.data.data.rows;
        });
    },
    getCardLevel() {
      let url = "/user/userMemberLevel/queryTreeList";
      this.$axios
        .get(url, {
          params: {
            platform: "体育宝",
            status: 1,
          },
        })
        .then((res) => {
          this.cardLevel = res.data.data;
        });
    },
    addCard() {
      let url = "/user/userMemberCard/insert";
      this.$axios
        .post(url, {
          ...this.form,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "success", message: "添加成功" });
            this.form = {
              platform: "体育宝",
              is_teach_paypal: true,
              status: 1,
            };
            this.addDialog = false;
            this.getCardList();
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    openUpdate(row) {
      this.temp = row;
      this.form = {};
      this.$set(this.form, "is_discount", row.is_discount);
      this.form.id = row.id;
      this.updateDialog = true;
    },
    update() {
      let url = "/user/userMemberCard/update";
      this.$axios.post(url, { ...this.form }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "warning", message: "修改成功!" });
          this.updateDialog = false;
          this.form = {
            platform: "体育宝",
            is_teach_paypal: true,
            status: 1,
          };
          this.temp = {};
          this.getCardList();
        } else {
          this.$message({ type: "error", message: res.data.message });
          this.form = {
            platform: "体育宝",
            is_teach_paypal: true,
            status: 1,
          };
        }
      });
    },
    frozen(row, status) {
      let url = "/user/userMemberCard/update";
      this.$axios.post(url, { id: row.id, status: status }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "warning", message: "修改成功!" });
          this.getCardList();
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.addTypeDialog = false;
          this.addDialog = false;
          this.updateTypeDialog = false;
          this.updateCardDialog = false;
          this.updateDialog = false;
          this.temp = {};
          this.level_id = null;
          this.form = {
            platform: "体育宝",
            is_teach_paypal: true,
            status: 1,
          };
          done();
        })
        .catch((_) => {});
    },
    end_time(start_time) {
      let a = new Date(start_time);
      a.setFullYear(a.getFullYear() + 1);
      a.setDate(a.getDate() - 1);
      return a.Format("yyyy-MM-dd");
    },
    getCardTypeID(v) {
      this.form.level_id = v[v.length - 1];
    },
    getCardLevelList() {
      let url = "/user/userMemberLevel/queryTreeList";
      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            status: 1,
            currentPage: this.currentPage,
            platform: "体育宝",
          },
        })
        .then((res) => {
          this.cardList = res.data.data;
        });
    },
    handleCurrentChange(v) {
      this.currentPage = v;
      this.getCardList();
    },
    getCardLevel() {
      let url = "/user/userMemberLevel/queryTreeList";
      this.$axios
        .get(url, {
          params: {
            platform: "体育宝",
            status: 1,
          },
        })
        .then((res) => {
          this.cardLevel = res.data.data;
        });
    },
    addCardLevel() {
      let url = "/user/userMemberLevel/insert";
      this.$axios
        .post(url, {
          ...this.form,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "success", message: "添加成功!" });
            this.addTypeDialog = false;
            this.form = {
              platform: "体育宝",
              is_teach_paypal: true,
              status: 1,
            };
            this.getCardLevelList();
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    openTypeUpdate(row) {
      this.temp = row;
      this.form = {};
      this.form.id = row.id;
      this.$set(this.form, "is_discount", row.is_discount);
      this.updateTypeDialog = true;
    },
    levelName(id) {
      let a = this.allCardLevel.find((item, index, arr) => {
        return item.id == id;
      });
      if (a) {
        return a;
      } else return {};
    },
    updateType() {
      let url = "/user/userMemberLevel/update";
      this.$axios.post(url, { ...this.form }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "warning", message: "修改成功!" });
          this.updateTypeDialog = false;
          this.form = {
            platform: "体育宝",
            is_teach_paypal: true,
            status: 1,
          };
          this.getCardLevelList();
        } else {
          this.$message({ type: "error", message: res.data.message });
          this.form = {
            platform: "体育宝",
            is_teach_paypal: true,
            status: 1,
          };
        }
      });
    },
    frozen(row) {
      let url = "/user/userMemberLevel/update";
      this.$axios.post(url, { id: row.id, status: 0 }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "warning", message: "修改成功!" });
          this.getCardLevelList();
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    handleClick(v) {
      if (v.index == 0) {
        this.getCardLevelList();
      }
      if (v.index == 1) {
        this.getCardList();
      }
    },
  },
  filters: {},
};
</script>
<style>
.form_text {
  margin-left: 1rem;
}
</style>
